import {baseRequestClient} from "@/request/request_client";
import {IDriveLicenceItem, ILicenceItem, RealNameItem} from "@/models/logistics_model";
import {IList, IListResult, IListResult3} from "@/models/base_model";

const requestClient = baseRequestClient

//  物流审核板块接口
export default class ApiLogisticsAudit {
    /**
     * GET 实名认证列表
     * @param params
     */
    static getRealNameList = async (params: any) => {
        return await requestClient.get<IListResult3<RealNameItem>>('/admin/logistics.Realname/list', params)
    }

    /**
     * GET 驾驶证认证列表
     * @param params
     */
    static getDriverLicenceList = async (params: any) => {
        return await requestClient.get<IListResult3<IDriveLicenceItem>>('/admin/logistics.DrivingLicense/list', params)
    }

    /**
     * GET 行驶证/物流运输证认证列表
     * @param params ?shop_admin_id&vehicle_travel_owner&vehicle_travel_time_min&vehicle_travel_time_max&car_nature&vehicle_travel_license_number&transportation_owner&road_transportation_permit_card&plate_number&car_type_id&page_no&page_size
     */
    static getLicenceList = async (params: any) => {
        return await requestClient.get<IListResult3<ILicenceItem>>('/admin/logistics.Application/list', params)
    }

    /**
     * GET 获取实名认证详情
     * @param id
     */
    static getRealNameDetail = async (id: any) => {
        return await requestClient.get<RealNameItem>('/admin/logistics.Realname/info', {id})
    }

    /**
     * GET 获取驾驶证认证详情
     * @param id
     */
    static getDriverLicenceDetail = async (id: any) => {
        return await requestClient.get<IDriveLicenceItem>('/admin/logistics.DrivingLicense/info', {id})
    }

    /**
     * GET 行驶证/物流运输证认证详情
     * @param id
     */
    static getLicenceDetail = async (id: any) => {
        return await requestClient.get<ILicenceItem>('/admin/logistics.Application/info', {id})
    }


    /**
     * POST 实名认证审核
     * @param data
     * {
         "id":17,
         "reason":"审核通过",
        "is_passed":true
       }
     */
    static auditReal = async (data: any) => {
        return await requestClient.post('/admin/logistics.Realname/audit', data)
    }


    /**
     * POST 审核驾驶证
     * @param data
     * {
        "driving_license_id":17,
        "reason":"审核通过",
        "is_passed":true
        }
     */
    static auditDriverLicence = async (data: any) => {
        return await requestClient.post('/admin/logistics.DrivingLicense/audit', data)
    }

    /**
     * POST 车辆认证审核
     * @param data
     * {
        "courier_application_id":17,
        "reason":"审核通过",
        "is_passed":true
        }
     */
    static auditLicence = async (data: any) => {
        return await requestClient.post('/admin/logistics.Application/audit', data)
    }

    /**
     * POST 上传车辆信息
     * @param courier_application_id
     */
    static updateCar = async (courier_application_id: any) => {
        return await requestClient.post('/admin/logistics.Application/push', {courier_application_id})
    }

    /**
     * POST 上传司机信息
     * @param id
     */
    static updateDriver = async (id: any) => {
        return await requestClient.post('/admin/logistics.Courier/push', {id})
    }

    // GET 获取车辆使用性质
    static getCarNature = async () => {
        return await baseRequestClient.get<any[]>('/shop/express.Courier/carNature', { page_no: 1, page_size: 30 })
    }

    // GET 获取车辆能源类型
    static getVehicleEnergyType = async () => {
        return await baseRequestClient.get<{value: string; text: string}[]>('/api/option/vehicleEnergyType')
    }

    // GET 获取车牌颜色代码
    static getVehicleColor = async () => {
        return await baseRequestClient.get<{value: string; text: string}[]>('/api/option/vehicleColor')
    }

    // GET 获取车辆类型
    static getCarType = async () => {
        return await baseRequestClient.get<any[]>('/api/option/vehicleType')
    }
}
