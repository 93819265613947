
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";
import {IDriveLicenceItem} from "@/models/logistics_model";
import {useRoute} from "vue-router";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";

interface IState {
  detail?: IDriveLicenceItem
  visible: boolean
  reason: string
  loading: boolean
  pass: boolean
}

export default defineComponent({
  name: "DrivingLicenceDetail",
  setup() {
    let editId: any;
    const route = useRoute()

    const state: IState = reactive({
      detail: undefined,
      visible: false,
      reason: '',
      loading: false,
      pass: false
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogisticsAudit.getDriverLicenceDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
        }
      }
    }

    // 审核
    const onAudit = async () => {
      const flg = state.pass
      if (!flg && !state.reason) {
        message.destroy()
        message.warning('请输入不通过的原因')
        return
      }
      state.loading = true
      const res = await ApiLogisticsAudit.auditDriverLicence({
        "driving_license_id": state.detail?.id,
        "reason": flg ? "审核通过" : state.reason,
        "is_passed": flg
      })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getDetail()
      } else {
        message.error(res?.msg || '审核失败');
      }
      state.visible = false
      state.reason = ''
    }

    // 设置modal显示隐藏
    const setVisible = (visible: boolean, pass: boolean) => {
      state.visible = visible
      state.pass = pass
      state.reason = ''
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      setVisible,
      onAudit,
    }
  }
})
